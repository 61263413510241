<!-- 领奖用户列表 -->
<template>
  <div class="vhc-container">
    <!-- 标题栏 -->
    <pageTitle pageTitle="中奖用户">
      <div slot="content">数据更新频率：5秒，建议结束任务后查看完整数据</div>
    </pageTitle>

    <!-- 顶部搜索栏 -->
    <div class="vhc-search-bar">
      <vh-button size="medium" type="primary" round @click="handleExport">导出明细</vh-button>
      <vh-button size="medium" type="text" round @click="handleSearch">刷新数据</vh-button>
      <vh-input
        size="medium"
        round
        class="search-input"
        placeholder="请输入用户昵称"
        v-model="keyword"
        clearable
        v-clearEmoij
        @clear="handleSearch"
        @keyup.enter.native="handleSearch"
      >
        <i class="vh-icon-search vh-input__icon" slot="prefix" @click="handleSearch"></i>
      </vh-input>
    </div>

    <!-- 内容区 -->
    <div class="vhc-container__bd">
      <div class="vhc-main" v-loading="loading">
        <!-- 数据列表 -->
        <div class="vhc-main-content" v-if="!(dataList.length == 0 && !isSearch) && !loading">
          <vh-table ref="tableList" :data="dataList" class="vhc-main-table">
            <vh-table-column
              prop="winner_nickname"
              label="用户名称"
              min-width="160"
              :show-overflow-tooltip="true"
            />
            <vh-table-column prop="take_award_phone" label="领奖手机号" min-width="120">
              <template slot-scope="scope">
                {{ scope.row.take_award_phone || '-' }}
              </template>
            </vh-table-column>
            <vh-table-column label="礼品信息" min-width="200">
              <template slot-scope="scope">
                <div class="award_box">
                  <vh-image
                    class="award_img_url"
                    fit="cover"
                    :src="scope.row.award_img_url"
                  ></vh-image>
                  <span class="award_name">{{ scope.row.award_name }}</span>
                </div>
              </template>
            </vh-table-column>
            <vh-table-column prop="push_time" label="推送时间" width="180">
              <template slot-scope="scope">
                {{ scope.row.push_at }}
              </template>
            </vh-table-column>
            <vh-table-column prop="winner_at" label="中奖时间" width="160"></vh-table-column>
            <template slot="empty">
              <noData :nullType="'search'" class="no_data" text="暂未搜索到您想要的内容"></noData>
            </template>
          </vh-table>

          <SPagination
            :total="total || 0"
            :page-size="pagination.limit"
            :currentPage="pagination.pageNo"
            @current-change="handleCurrentChange"
            @size-change="handleSizeChange"
          ></SPagination>
        </div>
        <noData
          v-else-if="!loading"
          :nullType="'nullData'"
          class="no_data"
          text="暂无中奖用户"
        ></noData>
      </div>
    </div>
  </div>
</template>
<script>
  import PageTitle from '@/components/PageTitle';
  import noData from '@/views/PlatformModule/Error/nullPage';
  import to from 'await-to-js';

  export default {
    name: 'InteractiveRewardUserList',
    components: {
      PageTitle,
      noData
    },
    data() {
      return {
        webinarId: '', //活动id
        watchRewardId: '', //互动有礼id
        isInited: false, // 是否有成功的请求
        loading: false,
        isEmpty: true, // 是否完全空数据
        total: 0,
        keyword: '',
        dataList: [],
        // 分页参数
        pagination: {
          pos: 0,
          limit: 20,
          pageNo: 1
        },
        isSearch: false
      };
    },
    methods: {
      // 导出明细
      async handleExport() {
        const params = {
          webinar_id: this.webinarId,
          watch_gift_id: this.watchRewardId,
          keyword: this.keyword // 用户昵称
        };
        const [err, res] = await to(this.$fetch('exportWatchWinnerList', params));
        if (err || !res || res.code != 200) {
          this.$message.error(err.msg || res?.msg || '请求失败');
          return;
        }
        this.$vhMessage.success(
          `导出申请成功，${
            this.$store.state.permissionValue.custom_download_toast || '请去下载中心下载'
          }`
        );
        this.$EventBus.$emit('saas_vs_download_change');
      },
      // 搜索
      handleSearch() {
        this.pagination.pos = 0;
        this.fetchData();
      },
      // 翻页事件
      handleCurrentChange(current) {
        this.pagination.pageNo = current; //当前页码
        this.pagination.pos = parseInt((current - 1) * this.pagination.limit);
        this.fetchData();
      },
      // 每页条数改变时触发
      handleSizeChange(limit) {
        this.pagination.limit = limit;
        this.pagination.pageNo = 1;
        this.pagination.pos = 0;
        this.fetchData();
      },
      // 请求数据
      fetchData: async function () {
        this.loading = true;
        this.keyword = this.keyword.trim();
        if (this.keyword) {
          this.isSearch = true;
        } else {
          this.isSearch = false;
        }
        const params = {
          webinar_id: this.webinarId,
          watch_gift_id: this.watchRewardId,
          keyword: encodeURI(this.keyword), // 用户昵称
          limit: this.pagination.limit,
          pos: this.pagination.pos
        };
        const [err, res] = await to(this.$fetch('getWatchWinnerList', params));
        this.loading = false;
        if (err || !res || res.code != 200) {
          this.$message.error(err.msg || res?.msg || '请求数据失败');
          return;
        }
        this.isInited = true;
        this.total = res.data.total || 0;
        this.dataList = res.data.list || [];
      }
    },
    created() {
      this.webinarId = this.$route.params.str || '';
      this.watchRewardId = this.$route.query.id || '';
    },
    mounted() {
      this.fetchData();
    }
  };
</script>
<style lang="less" scoped>
  .award_box {
    display: flex;
    align-items: center;
    .award_img_url {
      display: inline-block;
      width: 80px;
      height: 80px;
      border-radius: 4px;
      margin-right: 16px;
    }
    .award_name {
      width: 0;
      flex: 1;
    }
  }
  .no_data {
    height: 620px;
    margin-top: 0 !important;
    display: flex;
    justify-content: center;
    align-items: center;
  }
</style>
